




















































import {Component, Vue} from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import moment from 'moment'
import { Table, TableColumn, Button } from "element-ui"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
mixins: [translations],
})
export default class Events extends Vue {

   logs: any = [];
   loading: Boolean = false;
   mounted() {
    this.getLogs();
  }

  async getLogs() {
    this.loading = true;
    await Customer.getDeviceLogs(this.$route.params.id)
      .then((response: any) => {

         let e = response.data.data;

         this.logs = e.sort((a: any, b: any) => {
            return new Date(b.attributes['created-at']).getTime() - new Date(a.attributes['created-at']).getTime();
         });
         this.loading = false;
        console.log("check logs", response.data.data);
      })
  }
  filterTag(value: any, row: any) {
      return row.attributes['level'] === value;
   }
  capitalizeFirstLetter(str: any) {
    if (typeof str !== 'string') return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

}
